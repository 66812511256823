import { Component, Output, EventEmitter } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { Subject, takeUntil } from 'rxjs'
import { AuthenticationService } from 'src/app/core/services/authentication.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  notFound: string | undefined
  loginForm?: FormGroup
  private onDestroy$ = new Subject<void>();

  constructor(private authenticationService: AuthenticationService, private router: Router, private store: Store) { }

  ngOnInit(): void {
    this.authenticationService.authenticationState.pipe(takeUntil(this.onDestroy$)).subscribe(authenticated => {
      if (authenticated) {
        // Redirect to home page
        this.notFound = undefined
        this.router.navigate(['hem'])
      }
    })

    this.loginForm = new FormGroup({
      username: new FormControl('', {
        validators: [
          Validators.required,
          Validators.email,
        ],
        updateOn: 'change'
      }),
      password: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(6)
        ],
        updateOn: 'change'
      })
    })
  }

  login() {
    if (this.loginForm?.value.username && this.loginForm?.value.password) {
      this.authenticationService.authenticate({ username: this.loginForm.value.username, password: this.loginForm.value.password }).pipe(takeUntil(this.onDestroy$)).subscribe({
        /* next: response => {}, */
        error: error => {
          this.notFound = "Fel användarnamn eller lösenord";
        }
      })
    }
  }

  forgotPassword() {
    this.router.navigate(['../glomt-losenord']);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
