import { Component, HostListener } from '@angular/core';
import {
  BookingDTO,
  DefaultService,
  EngineTypeDTO,
  FeatureWithPriceDTO,
  FlightDTO,
  MainFeatureDTO,
  OrderDTO,
  PrepaidTicketDTO,
  ResourceDTO,
  ResourceStatusDTO,
  VehicleTypeDTO,
} from 'parking-sdk';
import { Subject, takeUntil, map, debounceTime } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import {
  AddOrderItem,
  ResetOrderState,
  UpdateOrderItem,
} from 'src/app/core/states/orders/orders.action';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { PrepaidSearchListener } from 'src/app/core/states/prepaid/prepaid.actions';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  DoneEditBooking,
  GetEditBooking,
} from 'src/app/core/states/bookings/booking.actions';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/core/services/order.service';
import { OrdersState } from 'src/app/core/states/orders/orders.state';
import { BookingService } from 'src/app/core/services/booking.service';

dayjs.extend(utc);

@Component({
  selector: 'app-booking-create',
  templateUrl: './booking-create.component.html',
  styleUrls: ['./booking-create.component.scss'],
})
export class BookingCreateComponent {
  /* Confirm reload window */
  @HostListener('window:beforeunload', ['$event'])
  confirmReload(event: BeforeUnloadEvent) {
    event.preventDefault();
  }
  private onDestroy$ = new Subject<void>();
  booking?: BookingDTO;
  editBooking?: BookingDTO;
  editOrder?: OrderDTO;

  invalidFormMessage?: string;
  hasChanges = false; // Used by confirm-guard service
  showSpinner: boolean = false; // Used to show spinner when adding many features

  /* Form */
  bookingForm?: FormGroup;
  selectedFeatures: FeatureWithPriceDTO[] = [];

  /* Status */
  parkingResources: ResourceDTO[] = [];
  parkingStatusResources?: ResourceStatusDTO[];

  /* Flight options */
  arrivalFlightOptions: FlightDTO[] = [];
  departureFlightOptions: FlightDTO[] = [];
  filteredArrivalFlightOptions: FlightDTO[] = [];
  filteredDepartureFlightOptions: FlightDTO[] = [];

  /* Other options */
  availableMainFeatures: MainFeatureDTO[] = [];
  availablePrepaidTickets: PrepaidTicketDTO[] = [];

  /* Booking price pre-edit */
  bookingPriceBeforeEdit: number | undefined = 0;
  totalPrice: number = 0;

  /* Vehicle */
  vehicleTypes?: VehicleTypeDTO[];
  engineTypes?: EngineTypeDTO[];

  checkedVehicleType?: VehicleTypeDTO;
  checkedEngineType?: EngineTypeDTO;

  constructor(
    private defaultService: DefaultService,
    private dateService: DateService,
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    private orderService: OrderService,
    private bookingService: BookingService,
    actions$: Actions
  ) {
    actions$
      .pipe(
        ofActionDispatched(PrepaidSearchListener),
        map((action: PrepaidSearchListener) => action.term),
        debounceTime(300),
        takeUntil(this.onDestroy$)
      )
      .subscribe((term) => {
        this.checkPrepaidTickets(term);
      });
  }

  ngOnInit() {
    /* Get parking resources */
    this.defaultService
      .getResources()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.parkingResources = v.map((resourceStatus) => ({
          ...resourceStatus,
          resource: undefined,
        }));
      });

    /* When used to edit booking, get booking id from url and if id, get the bookning to edit */
    const editBookingId = Number(this.router.url.split('/')[2]);

    this.store
      .dispatch(new GetEditBooking(editBookingId || undefined))
      .subscribe(() => {
        /* When get edit booking is finished, get current edit booking */
        this.store
          .select(BookingsState.currentEditBooking)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((editBooking) => {
            this.editBooking = editBooking;
            this.booking = { ...this.editBooking };

            this.defaultService
              .getVehicleTypes()
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((v) => {
                this.vehicleTypes = v;

                this.defaultService
                  .getEngineTypes()
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe((v) => {
                    this.engineTypes = v;

                    /* Set checked value for radio buttons */
                    this.checkedEngineType =
                      this.editBooking?.engineType ||
                      this.engineTypes?.[0] ||
                      undefined;

                    this.checkedVehicleType =
                      this.editBooking?.vehicleType ||
                      this.vehicleTypes?.[0] ||
                      undefined;

                    /* Create form group, set edit value or undefined */
                    this.bookingForm = this.formBuilder.group({
                      departureDate: new FormControl<Date | undefined>(
                        this.editBooking?.departureFlight?.departureDate ||
                          undefined
                      ),
                      arrivalDate: new FormControl<Date | undefined>(
                        this.editBooking?.arrivalFlight?.departureDate ||
                          undefined
                      ),
                      selectedDepartureFlight: new FormControl<
                        FlightDTO | string | undefined
                      >(this.editBooking?.departureFlight || undefined),
                      selectedArrivalFlight: new FormControl<
                        FlightDTO | string | undefined
                      >(this.editBooking?.arrivalFlight || undefined),
                      parkingStartDate: new FormControl<
                        Date | string | undefined
                      >(this.editBooking?.departureDate || undefined, [
                        Validators.required,
                      ]),
                      parkingEndDate: new FormControl<
                        Date | string | undefined
                      >(this.editBooking?.arrivalDate || undefined, [
                        Validators.required,
                      ]),
                      parkingStartTime: new FormControl<string | undefined>(
                        this.editBooking?.departureDate
                          ? this.dateService.formatTime(
                              this.editBooking.departureDate
                            )
                          : undefined,
                        [Validators.required]
                      ),
                      parkingEndTime: new FormControl<string | undefined>(
                        this.editBooking?.arrivalDate
                          ? this.dateService.formatTime(
                              this.editBooking.arrivalDate
                            )
                          : undefined,
                        [Validators.required]
                      ),
                      location: new FormControl<ResourceDTO | undefined>(
                        this.editBooking?.resource || undefined,
                        [Validators.required]
                      ),
                      name: new FormControl<string | undefined>(
                        this.editBooking?.name || undefined,
                        [Validators.required]
                      ),
                      phoneNumber: new FormControl<string | undefined>(
                        this.editBooking?.phone || undefined,
                        [Validators.required, Validators.minLength(7)]
                      ),
                      email: new FormControl<string | undefined>(
                        this.editBooking?.email || undefined,
                        [Validators.required, Validators.email]
                      ),
                      registrationNumber: new FormControl<string | undefined>(
                        this.editBooking?.registrationNumber || undefined,
                        [Validators.required]
                      ),
                      passengers: new FormControl<number | undefined>(
                        this.editBooking?.qtyPersons || undefined,
                        [Validators.required]
                      ),
                      notOnlyCarryOnLuggage: new FormControl<boolean>(
                        this.editBooking?.handLuggageOnly == undefined
                          ? true
                          : !this.editBooking.handLuggageOnly
                      ),
                      prepaid: new FormControl<PrepaidTicketDTO | 'none'>(
                        this.editBooking?.prepaidTicket || 'none'
                      ),
                      comment: new FormControl<string | undefined>(
                        this.editBooking?.comment || undefined
                      ),
                      childSafetySeat: new FormControl<boolean | undefined>(
                        this.editBooking?.childSafetySeat
                      ),
                      newsLetter: new FormControl<boolean | undefined>(
                        this.editBooking?.acceptNewsletter
                      ),
                      vehicleType: new FormControl<VehicleTypeDTO | undefined>(
                        this.editBooking?.vehicleType ||
                          this.vehicleTypes?.[0] ||
                          undefined
                      ),
                      engineType: new FormControl<EngineTypeDTO | undefined>(
                        this.editBooking?.engineType ||
                          this.engineTypes?.[0] ||
                          undefined
                      ),
                    });

                    /* Features */
                    this.defaultService
                      .getOrdersAdmin(
                        undefined,
                        1,
                        undefined,
                        undefined,
                        undefined,
                        this.editBooking?.bookingId
                      )
                      .pipe(takeUntil(this.onDestroy$))
                      .subscribe((orders) => {
                        if (orders.content?.length) {
                          this.editOrder = orders.content[0];
                          this.bookingPriceBeforeEdit =
                            orders.content[0].totalAmount;
                        }

                        this.getAndSetFeatures();
                      });

                    /* Listen to input changes */
                    this.bookingForm
                      ?.get('departureDate')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe((v) => {
                        this.bookingForm?.controls[
                          'selectedDepartureFlight'
                        ].setValue(undefined);
                        this.suggestParking();
                        this.updateOrder();
                      });
                    this.bookingForm
                      ?.get('arrivalDate')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe((v) => {
                        this.bookingForm?.controls[
                          'selectedArrivalFlight'
                        ].setValue(undefined);
                        this.suggestParking();
                        this.updateOrder();
                      });
                    this.bookingForm
                      ?.get('selectedDepartureFlight')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe((v) => {
                        this.filter(v, 'departure');
                        this.suggestParking();
                        this.updateOrder();
                      });
                    this.bookingForm
                      ?.get('selectedArrivalFlight')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe((v) => {
                        this.filter(v, 'arrival');
                        this.suggestParking();
                        this.updateOrder();
                      });
                    this.bookingForm
                      ?.get('registrationNumber')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe((v) => {
                        this.invalidFormMessage = '';
                        v && this.store.dispatch(new PrepaidSearchListener(v));
                      });
                    this.bookingForm
                      ?.get('parkingStartDate')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                        this.checkAvailability;
                      });
                    this.bookingForm
                      ?.get('parkingStartTime')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                        this.checkAvailability;
                      });
                    this.bookingForm
                      ?.get('parkingEndDate')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                        this.checkAvailability;
                      });
                    this.bookingForm
                      ?.get('parkingEndTime')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                        this.checkAvailability;
                      });
                    this.bookingForm
                      ?.get('passengers')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                      });
                    this.bookingForm
                      ?.get('email')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                      });
                    this.bookingForm
                      ?.get('phoneNumber')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                      });
                    this.bookingForm
                      ?.get('name')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                      });
                    this.bookingForm
                      ?.get('location')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.invalidFormMessage = '';
                        this.updateOrder();
                        this.getAndSetFeatures();
                        if (
                          this.bookingForm?.controls['registrationNumber'].value
                        )
                          this.checkPrepaidTickets(
                            this.bookingForm?.controls['registrationNumber']
                              .value
                          );
                      });
                    this.bookingForm
                      ?.get('vehicleType')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.updateOrder();
                        this.getAndSetFeatures();
                      });
                    this.bookingForm
                      ?.get('engineType')
                      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.updateOrder();
                        this.getAndSetFeatures();
                      });

                    /* Listen to form group changes */
                    this.bookingForm?.valueChanges
                      .pipe(takeUntil(this.onDestroy$))
                      .subscribe(() => {
                        this.hasChanges = this.bookingForm?.dirty || false;
                      });

                    this.updateOrder();

                    /* Add, if editBooking get flight options, prepaidtickets, availability */
                    if (editBooking) {
                      this.checkAvailability();
                      this.updateOrder();
                      editBooking.registrationNumber &&
                        this.checkPrepaidTickets(
                          editBooking.registrationNumber
                        );
                      if (
                        editBooking.departureFlight ||
                        editBooking.departureFlightNumber
                      ) {
                        this.findFlights('departure');
                      }
                      if (
                        editBooking.arrivalFlight ||
                        editBooking.arrivalFlightNumber
                      ) {
                        this.findFlights('arrival');
                      }
                    }
                  });
              });
          });
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new DoneEditBooking()).subscribe(() => {
      this.onDestroy$.next(undefined);
      this.onDestroy$.complete();
    });
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  hasFeature(order: OrderDTO | undefined, featureId: number): boolean {
    return this.booking && order
      ? this.bookingService.hasFeature(order, featureId, this.booking)
      : false;
  }

  addFeatureFormControl(name: number, value: boolean): void {
    if (this.bookingForm)
      this.bookingForm.addControl(
        name.toString(),
        new FormControl<FeatureWithPriceDTO | boolean>(value)
      );
  }

  /* Functions to send to angular material autocomplete component, needs to be arrowfn to be able to use local functions in here. */
  public displayDepartureFn = (option: FlightDTO): string => {
    return option
      ? `${option.flightNumber} ${option.arrivalAirport} ${this.formatTime(
          option.scheduledDepartureDate
        )}`
      : '';
  };
  public displayArrivalFn = (option: FlightDTO): string => {
    return option
      ? `${option.flightNumber} ${option.departureAirport} ${this.formatTime(
          option.scheduledArrivalDate
        )}`
      : '';
  };
  comparePrepaidObjects(t1: any, t2: any): boolean {
    return t1 && t2 ? t1.prepaidTicketId === t2.prepaidTicketId : t1 === t2;
  }
  /* End of angular material functions */

  filter(term: string | object, direction: 'departure' | 'arrival'): void {
    /* Term can be object or string */
    if (typeof term == 'string') {
      if (direction === 'departure') {
        this.filteredDepartureFlightOptions = this.bookingService.filterFlights(
          this.departureFlightOptions,
          'departure',
          term
        );
      } else {
        this.filteredArrivalFlightOptions = this.bookingService.filterFlights(
          this.arrivalFlightOptions,
          'arrival',
          term
        );
      }
    }
  }

  updateOrder(): void {
    this.updateBooking();
    this.calculateTotal();
  }

  calculateTotal() {
    this.totalPrice = this.bookingService.calculateTotalPrice(
      this.bookingForm?.controls['location']?.value,
      this.parkingStatusResources,
      this.selectedFeatures,
      this.bookingForm?.controls['prepaid'].value
    );
  }

  setFlightValues() {
    /* Check if selected flight is string/object and set correct variable */
    let departureFlightObj: FlightDTO | undefined = undefined;
    let arrivalFlightObj: FlightDTO | undefined = undefined;
    let arrivalFlightInput: string | undefined = undefined;
    let departureFlightInput: string | undefined = undefined;

    if (this.bookingForm) {
      if (
        typeof this.bookingForm.controls['selectedDepartureFlight']?.value ==
        'object'
      ) {
        departureFlightObj =
          this.bookingForm.controls['selectedDepartureFlight']?.value;
        departureFlightInput = undefined;
      } else {
        departureFlightInput =
          this.bookingForm.controls['selectedDepartureFlight']?.value;
        departureFlightObj = undefined;
      }
      if (
        typeof this.bookingForm.controls['selectedArrivalFlight']?.value ==
        'object'
      ) {
        arrivalFlightObj =
          this.bookingForm.controls['selectedArrivalFlight']?.value;
        arrivalFlightInput = undefined;
      } else {
        arrivalFlightInput =
          this.bookingForm.controls['selectedArrivalFlight']?.value;
        arrivalFlightObj = undefined;
      }
    }

    return {
      departureFlightObj,
      arrivalFlightObj,
      arrivalFlightInput,
      departureFlightInput,
    };
  }

  updateBooking(): void {
    if (this.bookingForm) {
      this.checkAvailability();
      const parkingStart: Date | undefined = this.dateService.composeDatetime(
        this.bookingForm.controls['parkingStartDate'].value || undefined,
        this.bookingForm.controls['parkingStartTime'].value || undefined
      );
      const parkingEnd: Date | undefined = this.dateService.composeDatetime(
        this.bookingForm.controls['parkingEndDate'].value || undefined,
        this.bookingForm.controls['parkingEndTime'].value || undefined
      );

      const prepaidTicket = this.bookingForm.controls['prepaid']?.value;

      let {
        departureFlightObj,
        arrivalFlightObj,
        arrivalFlightInput,
        departureFlightInput,
      } = this.setFlightValues();

      this.booking = {
        ...this.booking,
        departureDate: parkingStart || undefined,
        arrivalDate: parkingEnd || undefined,
        departureFlight: departureFlightObj || undefined,
        arrivalFlight: arrivalFlightObj || undefined,
        departureFlightNumber: departureFlightInput || undefined, // If no flight selected, use input
        arrivalFlightNumber: arrivalFlightInput || undefined, // If no flight selected, use input
        travelHomeFrom:
          this.bookingForm.controls['selectedArrivalFlight']?.value
            ?.departureAirport || undefined,
        registrationNumber:
          this.bookingForm.controls[
            'registrationNumber'
          ]?.value?.toUpperCase() || undefined,
        resource: this.bookingForm.controls['location']?.value || undefined,
        qtyPersons: this.bookingForm.controls['passengers']?.value || undefined,
        name: this.bookingForm.controls['name']?.value || undefined,
        phone: this.bookingForm.controls['phoneNumber']?.value || undefined,
        email: this.bookingForm.controls['email']?.value || undefined,
        handLuggageOnly:
          this.bookingForm.controls['notOnlyCarryOnLuggage']?.value == true
            ? false
            : true,
        comment: this.bookingForm.controls['comment']?.value || undefined,
        prepaidTicket: prepaidTicket !== 'none' ? prepaidTicket : undefined,
        childSafetySeat:
          this.bookingForm.controls['childSafetySeat']?.value || undefined,
        acceptNewsletter:
          this.bookingForm.controls['newsLetter']?.value || undefined,
        vehicleType:
          this.bookingForm.controls['vehicleType']?.value || undefined,
        engineType: this.bookingForm.controls['engineType']?.value || undefined,
      };
    }
  }

  updateFeatures(): void {
    /* Filter selected features and connect car to feature */
    this.selectedFeatures = this.filterSelectedFeatures().map((feature) => {
      return {
        ...feature,
        registrationNumber: this.booking?.registrationNumber,
      };
    });
  }

  getAndSetFeatures(): void {
    /* Create form controls for features, set previous value or false */
    this.defaultService
      .getMainFeaturesByBooking(this.booking)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.availableMainFeatures = v;
        v.forEach((mainFeature) => {
          mainFeature.features?.forEach((feature) => {
            if (feature.featureId !== undefined && this.bookingForm) {
              this.addFeatureFormControl(
                feature.featureId,
                this.hasFeature(this.editOrder, feature.featureId)
              );
            }
          });
        });
        this.updateFeatures();
        this.updateOrder();
      });
  }

  getVehicleOptions(): void {
    this.defaultService
      .getVehicleTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.vehicleTypes = v;
      });

    this.defaultService
      .getEngineTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.engineTypes = v;
      });
  }

  formatOnInputChange(
    event: Event,
    type:
      | 'parkingStartTime'
      | 'parkingEndTime'
      | 'departureDate'
      | 'arrivalDate'
  ): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (this.bookingForm) {
      switch (type) {
        case 'parkingStartTime':
          this.bookingForm.controls['parkingStartTime'].setValue(
            this.formatTimeInput(this.sanitizeNumericInput(inputValue))
          );
          break;
        case 'parkingEndTime':
          this.bookingForm.controls['parkingEndTime'].setValue(
            this.formatTimeInput(this.sanitizeNumericInput(inputValue))
          );
          break;
        case 'departureDate':
          if (inputValue.length >= 8 && this.formatDateInput(inputValue))
            this.bookingForm.controls['departureDate'].setValue(
              this.formatDateInput(inputValue)
            );
          break;
        case 'arrivalDate':
          if (inputValue.length >= 8 && this.formatDateInput(inputValue))
            this.bookingForm.controls['arrivalDate'].setValue(
              this.formatDateInput(inputValue)
            );
          break;
      }
    }
  }

  sanitizeNumericInput(input: string): string {
    // Remove non-numeric characters
    const numericInput = input.replace(/[^0-9]/g, '');

    // Format to HH:mm
    return numericInput;
  }

  formatTimeInput(input: string): string {
    if (input.length >= 4) {
      const hours = input.slice(0, 2);
      const minutes = input.slice(2, 4);
      const formattedTime = `${hours}:${minutes}`;

      // Check for valid hours (0-23)
      if (+hours >= 0 && +hours <= 23) {
        return formattedTime;
      } else {
        return 'fel tidsformat';
      }
    } else {
      return input;
    }
  }

  formatDateInput(input: string): Date | undefined {
    let inputArray: string[] = [];
    if (input.length >= 8) {
      inputArray = input.split('');
      if (inputArray[4] !== '-') inputArray.splice(4, 0, '-');
      if (inputArray[7] !== '-') inputArray.splice(7, 0, '-');
    }
    return inputArray.length === 10 ? new Date(inputArray.join('')) : undefined;
  }

  formatTime(date: Date | string | undefined): string {
    return (date && this.dateService.formatTime(date)) || '';
  }

  formatDate(date: Date | string | undefined): string {
    return (date && this.dateService.formatDate(date)) || '';
  }

  findFlights(direction: 'departure' | 'arrival'): void {
    if (this.bookingForm) {
      let date =
        direction == 'departure'
          ? this.bookingForm.controls['departureDate'].value
          : this.bookingForm.controls['arrivalDate'].value;

      if (date) {
        const dateObj = dayjs(date).format();

        this.defaultService
          .getFlightsAdmin(dateObj.split('T')[0], direction.toLocaleUpperCase())
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((v) => {
            if (this.bookingForm) {
              if (direction == 'departure') {
                /* Set flight options */
                this.departureFlightOptions = v;
                this.filteredDepartureFlightOptions = [];
              } else {
                this.arrivalFlightOptions = v;
                this.filteredArrivalFlightOptions = [];
              }
            }
          });
      } else {
        console.error('Error fetching flights');
      }
    }
  }

  private createDate(date: string, time: string) : Date {
    if (! time || time === '') {
      time = '00:00';
    }
    return new Date(
      dayjs(
        `${new Date(date).toISOString().split('T')[0]}T${
          time
        }`
      )
      .format()
    );
  }

  suggestParking(): void {
    if (this.bookingForm) {
      if (
        (!this.bookingForm.controls['departureDate'].value &&
          !this.bookingForm.controls['selectedDepartureFlight'].value &&
          !this.bookingForm.controls['arrivalDate'].value &&
          !this.bookingForm.controls['selectedArrivalFlight'].value) ||
        (this.bookingForm.controls['departureDate'].value &&
          this.bookingForm.controls['selectedDepartureFlight'].value &&
          this.bookingForm.controls['arrivalDate'].value &&
          this.bookingForm.controls['selectedArrivalFlight'].value)
      ) {
        /* Update entire parking period */
        
        /* Use selected flight if we have it, otherwise use old time before date (can be undefined) */
        const oldParkingFrom = this.createDate(this.bookingForm.controls['departureDate'].value, this.bookingForm.controls['parkingStartTime'].value);
        const oldParkingTo = this.createDate(this.bookingForm.controls['arrivalDate'].value, this.bookingForm.controls['parkingEndTime'].value);
        const suggestedParkingFrom =
          this.bookingForm.controls['selectedDepartureFlight'].value
            ?.suggestedParkingFrom ||
            oldParkingFrom ||
            this.bookingForm.controls['departureDate'].value;

        const suggestedParkingTo =
          this.bookingForm.controls['selectedArrivalFlight'].value
            ?.suggestedParkingTo ||
            oldParkingTo ||
            this.bookingForm.controls['arrivalDate'].value;

        this.bookingForm.controls['parkingStartTime'].setValue(
          this.formatTime(suggestedParkingFrom)
        );
        this.bookingForm.controls['parkingStartDate'].setValue(
          this.formatDate(suggestedParkingFrom)
        );
        this.bookingForm.controls['parkingEndTime'].setValue(
          this.formatTime(suggestedParkingTo)
        );
        this.bookingForm.controls['parkingEndDate'].setValue(
          this.formatDate(suggestedParkingTo)
        );
      } else if (
        !this.bookingForm.controls['departureDate'].value &&
        this.bookingForm.controls['arrivalDate'].value
      ) {
        /* Only suggest parking to */
        const suggestedParkingTo =
          this.bookingForm.controls['selectedArrivalFlight'].value
            ?.suggestedParkingTo ||
          this.bookingForm.controls['arrivalDate'].value;

        this.bookingForm.controls['parkingEndTime'].setValue(
          this.formatTime(suggestedParkingTo)
        );
        this.bookingForm.controls['parkingEndDate'].setValue(
          this.formatDate(suggestedParkingTo)
        );
      }
    }
    this.checkAvailability();
  }

  checkAvailability() {
    if (
      this.bookingForm &&
      this.bookingForm.controls['parkingStartDate'].value &&
      this.bookingForm.controls['parkingStartTime'].value &&
      this.bookingForm.controls['parkingEndDate'].value &&
      this.bookingForm.controls['parkingEndTime'].value
    ) {
      const fromDate = this.createDate(this.bookingForm.controls['parkingStartDate'].value, this.bookingForm.controls['parkingStartTime'].value);
      const toDate = this.createDate(this.bookingForm.controls['parkingEndDate'].value, this.bookingForm.controls['parkingEndTime'].value);

      this.defaultService
        .getAvailableResourcesByDates(fromDate, toDate)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((v) => {
          this.parkingStatusResources = v || [];
          this.calculateTotal();
        });
    } else {
      this.parkingStatusResources = [];
    }
  }

  checkPrepaidTickets(term: string) {
    if (
      this.booking &&
      this.booking.resource?.resourceId &&
      this.booking.departureDate &&
      this.booking.arrivalDate
    ) {
      this.defaultService
        .findPrepaidTicketsByBookingAdmin(
          term,
          this.booking.resource.resourceId,
          this.booking.departureDate,
          this.booking.arrivalDate
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((tickets) => {
          this.availablePrepaidTickets = [];
          tickets.forEach((ticket) => {
            // Check if ticket is selectable
            if (this.bookingForm) {
              if (ticket.maxPark !== undefined) {
                /* If this registration number already using ticket*/
                if (
                  ticket.bookings?.some(
                    (booking) =>
                      booking.registrationNumber ===
                      this.booking?.registrationNumber
                  )
                ) {
                  this.availablePrepaidTickets.unshift(ticket);

                  /* Only set value if not editing */
                  !this.editBooking &&
                    this.bookingForm.controls['prepaid'].setValue(
                      this.availablePrepaidTickets[0]
                    );
                  this.updateOrder();
                } else if ((ticket.bookings?.length || 0) <= ticket.maxPark) {
                  this.availablePrepaidTickets.unshift(ticket);

                  /* Only set value if not editing */
                  !this.editBooking &&
                    this.bookingForm.controls['prepaid'].setValue(
                      this.availablePrepaidTickets[0]
                    );
                  this.updateOrder();
                }
              }
            }
          });
        });
    }
  }

  ticketIsFull(ticket: any): boolean {
    return this.booking
      ? this.bookingService.ticketIsFull(ticket, this.booking)
      : false;
  }

  filterSelectedFeatures(): FeatureWithPriceDTO[] {
    return this.bookingForm
      ? this.bookingService.filterSelectedFeatures(
          this.bookingForm,
          this.availableMainFeatures
        )
      : [];
  }

  resetFormAfterDuplicate(): void {
    /* Reset formControls that's non-common for duplicate booking */
    const resetControls = [
      'location',
      'registrationNumber',
      'passengers',
      'notOnlyCarryOnLuggage',
      'discount',
      'prepaid',
      'passengers',
      'childSafetySeat',
    ];

    resetControls.forEach((control) => {
      this.bookingForm?.controls[control]?.reset(
        control === 'notOnlyCarryOnLuggage' ? true : undefined
      );
    });

    /* For each feature set checkbox to unchecked  */
    this.availableMainFeatures.forEach((mainFeature) => {
      mainFeature.features?.forEach((feature) => {
        if (feature.featureId !== undefined && this.bookingForm) {
          this.bookingForm.controls[feature.featureId].reset(undefined);
        }
      });
    });
    this.selectedFeatures = [];
  }

  onSubmit(duplicate: boolean) {
    if (!this.bookingForm?.controls['location'].value) {
      /* Angular Material does not handle styling for invalid radio buttons. This is a manual solution. */
      this.bookingForm?.get('location')?.markAsDirty();
    }
    if (this.bookingForm && this.bookingForm.invalid) {
      this.invalidFormMessage = 'Ett eller flera fält är ogiltiga';
    } else {
      if (this.booking) {
        /* If editing */
        if (
          this.editBooking &&
          this.editBooking.bookingId &&
          this.editOrder &&
          this.editOrder?.orderId
        ) {
          const bookingOrderItem = this.editOrder.orderItems?.find(
            (item) =>
              item.orderItemType === 'RESOURCE' &&
              item.booking?.bookingId === this.booking?.bookingId
          );
          if (bookingOrderItem) bookingOrderItem.booking = this.booking;

          if (bookingOrderItem?.orderItemId) {
            /* Update existing order */
            this.store
              .dispatch(
                new UpdateOrderItem(
                  this.editOrder.orderId,
                  bookingOrderItem.orderItemId,
                  bookingOrderItem
                )
              )
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.hasChanges = false;

                // Check if any features are included in the booking
                let featuresToHandle = false;
                if (this.selectedFeatures.length > 0) {
                  featuresToHandle = true;
                } else {
                  this.editOrder?.orderItems?.forEach((item) => {
                    if (item.feature) {
                      featuresToHandle = true;
                    }
                  });
                }

                // If features included, update them before navigating. If not, go to navigation
                if (featuresToHandle && this.booking) {
                  if (this.selectedFeatures.length > 1) this.showSpinner = true;
                  this.orderService
                    .updateOrderFeatures(
                      this.booking,
                      this.selectedFeatures,
                      this.editOrder
                    )
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(() => {
                      /* When finished updating, go to checkout */
                      this.hasChanges = false;
                      this.showSpinner = false;
                      this.store
                        .selectOnce(OrdersState.order)
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe((order) => {
                          // Only navigate to checkout if price is different after editing
                          if (
                            order.totalAmount !== this.bookingPriceBeforeEdit
                          ) {
                            this.router.navigate(['hantera-betalning']);
                          } else {
                            // Changes saved without payment, remove order from orderstate and go back to details
                            this.store
                              .dispatch(new ResetOrderState())
                              .pipe(takeUntil(this.onDestroy$))
                              .subscribe(() => {
                                this.router.navigate([
                                  'bokning',
                                  this.editBooking?.bookingId,
                                ]);
                              });
                          }
                        });
                    });
                } else {
                  // Navigation without updating features
                  this.hasChanges = false;

                  this.store
                    .selectOnce(OrdersState.order)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe((order) => {
                      if (order.totalAmount !== this.bookingPriceBeforeEdit) {
                        this.router.navigate(['hantera-betalning']);
                      } else {
                        this.store
                          .dispatch(new ResetOrderState())
                          .pipe(takeUntil(this.onDestroy$))
                          .subscribe(() => {
                            this.router.navigate([
                              'bokning',
                              this.editBooking?.bookingId,
                            ]);
                          });
                      }
                    });
                }
              });
          }
        } else {
          /* Add to new order */
          this.store
            .dispatch(
              new AddOrderItem({
                orderItemType: 'RESOURCE',
                booking: this.booking,
              })
            )
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              // Make sure the second operation gets called after the first
              if (this.selectedFeatures.length) {
                if (this.selectedFeatures.length > 1) this.showSpinner = true;
                this.orderService
                  .addOrderFeatures(this.selectedFeatures)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(() => {
                    if (duplicate) {
                      this.resetFormAfterDuplicate();
                      this.selectedFeatures = [];
                      this.updateBooking();
                      this.checkAvailability();
                      this.hasChanges = false;
                      this.showSpinner = false;
                    } else {
                      this.hasChanges = false;
                      this.showSpinner = false;
                      this.router.navigate(['hantera-betalning']);
                    }
                  });
              } else {
                if (duplicate) {
                  this.resetFormAfterDuplicate();
                  this.selectedFeatures = [];
                  this.updateBooking();
                  this.checkAvailability();
                  this.hasChanges = false;
                } else {
                  this.hasChanges = false;
                  this.router.navigate(['hantera-betalning']);
                }
              }
            });
        }
      }
    }
  }
}
