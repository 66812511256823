import { Component } from '@angular/core';
import { AuthenticationService } from './core/services/authentication.service';
import { Store } from '@ngxs/store';
import { UsersState } from './core/states/users/users.state';
import { DefaultService, PartnerDTO, RoleDTO, UserDTO } from 'parking-sdk';
import { environment } from '../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, delay, filter, takeUntil } from 'rxjs';
import { DrawerServiceService } from './core/services/drawer-service.service';
import { SetCurrentPartner } from './core/states/partner/partner.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'administration';
  description: string = '';
  version: string = '';
  isProd = true;
  isAuthenticated = false;
  drawerActive: boolean = false;
  user?: UserDTO;
  isAdmin: boolean = false;
  partner?: PartnerDTO;
  hideLayout: boolean = false;
  drawerContent?: string;

  private onDestroy$ = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private drawerService: DrawerServiceService,
    private defaultService: DefaultService
  ) {
    // Get hideLayout parameter from route
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => {
        this.hideLayout =
          this.activatedRoute.snapshot.firstChild?.data['hideLayout'];
      });
  }

  logout() {
    this.authenticationService.logout();
    this.isAuthenticated = !!this.authenticationService.isAuthenticated();
  }

  ngOnInit() {
    this.description = environment.description;
    this.isProd = environment.isProd;
    this.version = environment.version;
    this.authenticationService.authenticationState
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.isAuthenticated = v;

        this.store
          .select(UsersState.loggedInUser)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((v) => {
            this.user = v;
            this.isAdmin = v?.roles
              ? v.roles.some((role: RoleDTO) => role.name === 'ROLE_ADMIN')
              : false;

            this.partner = v?.roles
              ? v.roles.find((role: RoleDTO) => role.name === 'ROLE_PARTNER')
                  ?.partner
              : undefined;

            if(this.partner){
              this.setCurrentPartner(this.partner)
            }
          });

        this.drawerService.currentContent
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((content) => {
            this.drawerContent = content;
          });
      });
  }

  setCurrentPartner(partner: PartnerDTO) {
    partner && this.store.dispatch(new SetCurrentPartner(partner)).pipe(takeUntil(this.onDestroy$), delay(0)).subscribe(() => {
      this.router.navigate(['/partnerportalen/' + partner.partnerId]);
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
