import { NgModule } from '@angular/core';
import { BookingComponent } from './booking.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { FlightInformationComponent } from './flight-information/flight-information.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    BookingComponent,
    OrderSummaryComponent,
    FlightInformationComponent,
  ],
  imports: [SharedModule],
  exports: [BookingComponent],
})
export class BookingModule {}
