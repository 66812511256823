import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DefaultService, JwtResponse, LoginRequest } from 'parking-sdk';
import {
  BehaviorSubject,
  Subject,
  takeUntil,
  tap,
  Observable,
  catchError,
  firstValueFrom,
} from 'rxjs';
import { SetLoggedInUser, ResetUsersState } from '../states/users/user.actions';
import { ResetOrderState } from '../states/orders/orders.action';
import { UsersState } from '../states/users/users.state';
import { ResetBookingState } from '../states/bookings/booking.actions';
import { ResetPartnerState } from '../states/partner/partner.action';

const TOKEN_KEY = 'auth-token';
const ORDER_TOKEN_KEY = 'order-auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);
  private onDestroy$ = new Subject();

  constructor(
    private router: Router,
    private orderService: DefaultService,
    private store: Store
  ) {
    this.authenticationState.next(!!this.isAuthenticated());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }

  authenticate(value: LoginRequest): Observable<JwtResponse> {
    return this.orderService.authenticateUser(value).pipe(
      takeUntil(this.onDestroy$),
      tap((jwtResponse: JwtResponse) => {
        if (jwtResponse.jwt) {
          this.storeToken(jwtResponse.jwt);
          this.authenticationState.next(true);
          this.store.dispatch(new SetLoggedInUser());
        }
      })
    );
  }

  isAuthenticated() {
    return this.getToken();
  }

  logout() {
    this.removeToken();
    this.store.dispatch(new ResetOrderState());
    this.store.dispatch(new ResetUsersState());
    this.store.dispatch(new ResetBookingState());
    this.store.dispatch(new ResetPartnerState());
    this.router.navigateByUrl('/logga-in');
    this.authenticationState.next(false);
  }

  storeToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }
  storeOrderToken(token: string) {
    localStorage.setItem(ORDER_TOKEN_KEY, token);
  }

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  }

  getToken(): string | null {
    let token = localStorage.getItem(TOKEN_KEY);
    return token;
  }
  getOrderToken(): string | null {
    let token = localStorage.getItem(ORDER_TOKEN_KEY);
    return token;
  }
}
