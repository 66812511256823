import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { BookingDTO } from 'parking-sdk';
import { Subject, map, debounceTime, takeUntil } from 'rxjs';
import { FindBookings } from 'src/app/core/states/bookings/booking.actions';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';
import { BookingSearchListener } from 'src/app/core/states/bookings/booking.actions';
import { OrdersState } from 'src/app/core/states/orders/orders.state';

@Component({
  selector: 'app-side-menu-search',
  templateUrl: './side-menu-search.component.html',
  styleUrls: ['./side-menu-search.component.scss'],
})
export class SideMenuSearchComponent {
  @Input() drawerActive = false;
  @Output() closeDrawer: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchBox') searchBox!: ElementRef<HTMLInputElement>;
  searchResultUpcoming?: any;
  searchResultHistory?: any;
  termNotFound?: string;
  searchInput = new FormControl<string | undefined>(undefined);
  showSpinner = false;
  termTooShort = false;
  activeOrder = false;

  private onDestroy$ = new Subject<void>();

  
  constructor(private store: Store, actions$: Actions) {
    actions$
      .pipe(
        ofActionDispatched(BookingSearchListener),
        map((action: BookingSearchListener) => action.term),
        debounceTime(300),
        takeUntil(this.onDestroy$)
      )
      .subscribe((term) => {
        this.store
          .dispatch(new FindBookings(term)).pipe(takeUntil(this.onDestroy$))
          .subscribe(() => this.getSearchResult(term));
      });
  }

  ngOnInit(){
    this.store.select(OrdersState.order).pipe(takeUntil(this.onDestroy$)).subscribe(order => {
      this.activeOrder = !!order?.orderId
    })
  }
 
  ngOnChanges(): void {
    this.termNotFound = undefined;
    this.searchResultUpcoming = undefined;
    this.searchResultHistory = undefined;
    this.searchInput.setValue(undefined);

    this.store.select(OrdersState.order).pipe(takeUntil(this.onDestroy$)).subscribe(order => {
      this.activeOrder = !!order?.orderId
    })
  }

  ngAfterViewChecked() {
    if (this.drawerActive) this.searchBox.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  search(term: string | undefined): void {
    if (term == undefined || !term.trim().length) {
      this.termNotFound = undefined;
      this.searchResultUpcoming = undefined;
      this.searchResultHistory = undefined;
      this.showSpinner = false;
      this.termTooShort = false;
    } else if (term.length < 3) {
      this.termNotFound = undefined;
      this.termTooShort = true;
    } else {
      this.termTooShort = false;
      this.showSpinner = true;
      this.store.dispatch(new BookingSearchListener(term));
    }
  }

  getSearchResult(term: string) {
    this.store
      .select(BookingsState.searchResultUpcoming).pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result && result.length > 0) {
          if (this.searchInput.value) this.searchResultUpcoming = result;
        } else {
          this.searchResultUpcoming = undefined;
        }
      });
    this.store.select(BookingsState.searchResultHistory).pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      if (result && result.length > 0) {
        if (this.searchInput.value) this.searchResultHistory = result;
      } else {
        this.searchResultHistory = undefined;
      }
    });
    if (!this.searchResultHistory && !this.searchResultUpcoming) {
      this.termNotFound = term;
    } else {
      this.termNotFound = undefined;
    }
    this.showSpinner = false;
  }

  getTotalPassengers(bookings: BookingDTO[]): number {
    return bookings.reduce(
      (acc: number, booking: BookingDTO) => (booking.qtyPersons || 0) + acc,
      0
    );
  }

  close() {
    this.closeDrawer.emit();
  }
}
